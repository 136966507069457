import ErrorLayout from '@/layouts/ErrorLayout'

export default [
  {
    path: '',
    component: ErrorLayout,
    children: [
      {
        path: '/error/not-found',
        name: 'error-not-found',
        component: () => import(/* webpackChunkName: "error-not-found" */ '@/pages/error/NotFoundPage.vue'),
      },
      {
        path: '/payments/complete',
        name: 'payment-complete',
        component: () => import(/* webpackChunkName: "error-not-found" */ '@/pages/payments/view/PaymentCompletePage.vue'),
      },
      {
        path: '/contracts/statute',
        name: 'statute',
        component: () => import(/* webpackChunkName: "profile" */ '@/pages/contract/view/Statute.vue')
      },
    ],
  }
]
