import axios from 'axios';

export async function fetchMyDetails() {
  const { data } = await axios.get('/me/');
  return data;
}

export async function updateMyAuthDetails(payload) {
  const { data } = await axios.put('/me/auth', payload);
  return data;
}

export async function updateMyContactDetails(payload) {
  const { data } = await axios.put('/me/contact', payload);
  return data;
}

export async function updateMyCompanyDetails(payload) {
  const { data } = await axios.put('/me/company', payload);
  return data;
}

export async function changeMyAvatar(formData) {
  const { data } = await axios.post('/me/change-avatar', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
}

export async function changeMyLogo(formData) {
  const { data } = await axios.post('/me/change-company-logo', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
}

export async function switchCompany(companyId) {
  const { data } = await axios.patch(`/me/switch-company/${companyId}`);
  return data;
}

// BANKING
export async function addBankAccount(payload) {
  const { data } = await axios.post('/me/banking', payload);
  return data;
}

export async function updateBankAccount(id, payload) {
  const { data } = await axios.put(`/me/banking/${id}`, payload);
  return data;
}

export async function getSingleBankAccount(id){
  const { data } = await axios.get(`/me/banking/${id}`);
  return data;
}

export async function getBankAccountsList(params){
  const { data } = await axios.get(`/me/banking`, { params });
  return data;
}

export async function removeBankAccount(id){
  const { data } = await axios.delete(`/me/banking/${id}`);
  return data;
}

// ADDITIONAL PLACE OF BUSINESS
export async function addAdditionalAddress(payload) {
  const { data } = await axios.post('/me/address', payload);
  return data;
}

export async function updateAdditionalAddress(id, payload) {
  const { data } = await axios.put(`/me/address/${id}`, payload);
  return data;
}

export async function getSingleAdditionalAddress(id){
  const { data } = await axios.get(`/me/address/${id}`);
  return data;
}

export async function getAdditionalAddressesList(params){
  const { data } = await axios.get(`/me/address`, { params });
  return data;
}

export async function removeAdditionalAddress(id){
  const { data } = await axios.delete(`/me/address/${id}`);
  return data;
}

