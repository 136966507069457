<template>
  <figure>
    <div class="font-weight-bold my-1">{{ $t(`$customizationMenu.$colors.${color}`) }}</div>
    <v-color-picker
      :value="theme[mode][color]"
      @input="value => handleColorChange(mode, color, value)"
      mode="hexa"
    />
  </figure>
</template>
<script>
  export default {
    name: 'CustomizationMenuColor',
    props: {
      mode: {
        type: String,
        default: 'light',
        validator: value => {
          return ['light', 'dark'].includes(value);
        }
      },
      theme: {
        type: Object,
        required: true,
      },
      color: {
        type: String,
        required: true,
        validator: value => {
          return ['primary', 'secondary', 'background', 'components'].includes(value);
        }
      }
    },
    data() {
      return {

      }
    },
    methods: {
      handleColorChange(mode, color, value) {
        const themeSettings = { ...this.theme }
        themeSettings[mode][color] = value
        this.$emit('update:theme', Object.assign(this.theme, { ...themeSettings }) );
        this.$vuetify.theme.themes[mode][color] = value;
      },
    }
  }
</script>
