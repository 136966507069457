import jwtDecode from "jwt-decode";

const SET_TOKEN = (state, value) => {
  localStorage.setItem('ototax-jwt-token', value);
  state.token = value;
};

const SET_TOKEN_ROLES = (state) => {
  const { roles } = jwtDecode(state.token);
  state.tokenRoles = roles;
}

const SET_READY = (state, value) => {
  state.ready = value;
};

const SET_USER = (state, user) => {
  state.user = user;
};

const SET_ROLES = (state, roles) => {
  state.roles = roles;
};

const CLEAR = state => {
  state.user = null;
  state.token = null;
  state.ready = false;
  state.roles = [];
  state.tokenRoles = [];
  localStorage.removeItem('ototax-jwt-token');
};

export default {
  SET_TOKEN,
  SET_READY,
  SET_USER,
  SET_ROLES,
  SET_TOKEN_ROLES,
  CLEAR,
}
