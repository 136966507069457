import { changeMyAvatar, changeMyLogo, switchCompany} from '@/api/me';
import { companyAppInit, userAppInit } from '@/api/init';
import { updateUserAppSettings } from '@/api/user-app-settings';
import UserAppSettingsEnum from '@/constants/userAppSettingsEnum';

const init = async ({ commit, rootGetters }) => {
  const userInitData = await userAppInit();
  const { mode } = userInitData;

  commit('SET_MODE', mode);

  if (rootGetters['auth/isGranted']('ROLE_BUSINESS_USER')) {
    const companyInitData = await companyAppInit();
    commit('SET_COMPANY', companyInitData);
  }
}

const changeUserSettings = async ({ commit, dispatch }, { mode }) => {
  await updateUserAppSettings({ name: UserAppSettingsEnum.MODE, namespace: 'color-scheme', value: mode });
  await dispatch('init');
}

const changeCompany = async ({ commit, dispatch }, companyId) => {
  await switchCompany(companyId);
  window.location.reload();
}

const changeProfileImage = async ({ commit }, { type, imageFile }) => {
  if (!imageFile) {
    return;
  }

  const formData = new FormData;
  formData.append('image', imageFile);

  try {
    commit('IS_CHANGE_PROFILE_IMAGE_LOADING', true);
    if (type === 'avatar') {
      await changeMyAvatar(formData);
    } else {
      await changeMyLogo(formData);
    }
  } finally {
    commit('IS_CHANGE_PROFILE_IMAGE_LOADING', false);
  }
};

export default {
  init,
  changeUserSettings,
  changeProfileImage,
  changeCompany
}
