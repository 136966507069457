import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework'
import * as directives from 'vuetify/lib/directives'
import i18n from './vue-i18n'
import config from '../configs'


Vue.use(Vuetify, {
  directives
})

export default new Vuetify({
  rtl: false,
  theme: {
    dark: config.theme.darkMode === 'dark',
    options: {
      customProperties: true
    },
    themes: {
      dark: config.theme.darkMode,
      light: config.theme.lightMode
    }
  },
  lang: {
    current: i18n.locale,
    t: (key, ...params) => i18n.t(key, params)
  }
})
