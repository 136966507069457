import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const initialState = {
  ready: false,
  token: localStorage.getItem('ototax-jwt-token'),
  user: null,
  roles: [],
  tokenRoles: [],
};

export default {
  namespaced: true,
  state: () => initialState,
  getters,
  actions,
  mutations
}
