import store from '@/store';

const DEFAULT_OPTIONS = {
  display: true,
};

const STATUS_CODE_MAPPING = {
  500: '$messages.internalServerError',
  400: '$messages.invalidInputData',
  401: '$messages.badCredentialsError',
};

function setupAxiosInterceptors(axios, handleError) {
  axios.interceptors.response.use(
    response => response,
    error => {
      handleError(error);
      return Promise.reject(error);
    },
  );
}

function install(Vue, settings) {
  const { i18n, axios } = settings;
  const debug = settings.debug || false;
  const displayErrorHandler = settings.displayError || null;

  const displayError = async errorMessage => {
    if (displayErrorHandler && typeof displayErrorHandler === 'function') {
      await displayErrorHandler({ errorMessage });
    }
  };

  const handleError = async (error, options) => {
    if (!error) return;

    options = Object.assign({}, DEFAULT_OPTIONS, options);
    let errorMessage = i18n.t('$messages.uknownErrorOccured');

    if (error.response) {
      const { response } = error;

      if (response.status === 401) {
        store.dispatch('auth/logout');
        window.location.reload();
        return;
      }

      const transKey =
        STATUS_CODE_MAPPING[response.status] || '$messages.uknownErrorOccured';

      if (transKey) {
        errorMessage = i18n.t(response.data);
      }
    }

    if (options.display) {
      await displayError(errorMessage);
    }

    if (debug) {
      console.error(error);
    }
  };

  if (axios) {
    setupAxiosInterceptors(axios, handleError);
  }

  Object.defineProperty(Vue.prototype, '$handleError', {
    get() {
      return handleError;
    },
  });
}

export default install;
