import mutations from './mutations';
import getters from './getters';

const initialState = {
  displayAccountingInfo: false,
  displayAuthInfo: false,
};

export default {
  namespaced: true,
  state: () => initialState,
  getters,
  mutations,
}
