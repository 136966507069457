<template>
  <v-footer v-bind="$attrs" v-on="$listeners" color="components" class="footer">
    <slot>
      <div class="overline footer__container">
        <div v-if="$vuetify.breakpoint.smAndUp" class="flex-wrap justify-start d-flex align-content-end">
          <p>{{ $t('$common.copyright') }}</p>
        </div>
       <div class="flex-wrap justify-end flex-auto d-flex">
         <span v-if="$vuetify.breakpoint.xsOnly" class="ototax-copyright">{{ $t('$common.copyright') }}</span>
         <a href="https://ergoproxy.pl" target="_blank" class="footer__link">
           <p class="mb-0">Powered by Ergo Proxy</p>
           <v-img class="ergo-proxy-logo" :src="logoImage" alt="Ergo Proxy company logo" />
         </a>
       </div>
      </div>
    </slot>
  </v-footer>
</template>
<script>
  export default {
    name: 'AppFooter',
    inheritAttrs: false,
    computed: {
      logoImage() {
        if (this.$vuetify.theme.isDark) {
          return `/images/logo/ergo-proxy-negative-logo.png`;
        }
        return `/images/logo/ergo-proxy-logo.png`
      },
    }
  }
</script>
<style scoped lang="scss">
  .footer {
    .ergo-proxy-logo {
      max-width: 50px;
      @media (max-width: 600px) {
        max-width: 40px;
      }
    }
    .ototax-copyright {
      margin: auto auto auto 0;
      font-size: 8px !important;
    }
    &__container {
      display: flex;
      width: 100%;
    }
    &__link {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.5rem;
      text-decoration: none;
      color: inherit;
      @media (max-width: 600px) {
        p {
          font-size: 8px !important;
        }
      }
    }
  }
</style>
