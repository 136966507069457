import { emitNotificationId } from "@/utils/event-bus";

export default {
  methods: {
    async handleNotificationClick(id) {
      const { $route } = this;
      if (!id) return;

      emitNotificationId(id);

      if ($route.params.notificationId === id) return;

      const { $router } = this;

      if (this.$isGrantedAdminOr('ROLE_OFFICE_WORKER')) {
        $router.push({ name: 'my-notifications-admin', params: { notificationId: id }});
        return;
      }
      $router.push({ name: 'my-notifications-user', params: { notificationId: id }});
    },
  }
}
