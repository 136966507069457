import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":"","left":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"elevation-2",attrs:{"icon":""}},on),[_c(VBadge,{attrs:{"color":"success","dot":"","bordered":"","offset-x":"10","offset-y":"10"}},[_c(VAvatar,{attrs:{"size":"40"}},[(_vm.user.authData && _vm.user.authData.avatarUrl)?_c(VImg,{attrs:{"src":_vm.user.authData.avatarUrl}}):_c(VImg,{attrs:{"src":"/images/avatars/avatar1.svg"}})],1)],1)],1)]}}])},[_c(VList,{attrs:{"dense":"","nav":"","color":"components"}},[(_vm.$isGrantedByToken('ROLE_ALLOWED_TO_SWITCH') && !_vm.$isGranted('ROLE_ALLOWED_TO_SWITCH'))?[_c(VListItem,{on:{"click":_vm.handleLogoutFromUserAccount}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-logout-variant")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('$menu.logoutFromUserAccount')))])],1)],1),_c(VDivider,{staticClass:"my-1"})]:_vm._e(),_vm._l((_vm.menu),function(item,index){return [(_vm.$isGranted(item.perm))?_c(VListItem,{key:index,attrs:{"to":item.routeName ? { name: item.routeName } : '',"exact":item.exact,"disabled":item.disabled,"link":""}},[_c(VListItemIcon,[_c(VIcon,{class:{ 'grey--text': item.disabled },attrs:{"small":""}},[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.key ? _vm.$t(item.key) : item.text))])],1)],1):_vm._e()]}),_c(VDivider,{staticClass:"my-1"}),_c(VListItem,{on:{"click":_vm.handleLogout}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-logout-variant")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('$menu.logout')))])],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }