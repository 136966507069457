<template>
  <div class="d-flex flex-grow-1">
    <app-toolbar
      @click="handleHamburgerClick"
      :is-sidebar-collapsed="sidebar.collapsed"
    />

    <app-sidebar
      v-model="sidebar.collapsed"
      v-bind="sidebar"
      :menu-items="navItems"
    />

    <v-main>
      <v-layout class="fill-height pt-1 px-1 pb-6 pt-sm-2 px-sm-2 pt-md-3 px-md-3" fluid>
        <v-layout>
          <transition name="fade" mode="out-in" appear>
            <router-view :key="$route.fullPath"/>
          </transition>
        </v-layout>
      </v-layout>

      <app-footer inset/>
    </v-main>
    <customization-menu v-if="$isGranted('ROLE_ADMIN')" />
  </div>
</template>

<script>
  import MenuItems from '../configs/navigation';
  import AppSidebar from '@/components/common/AppSidebar';
  import AppToolbar from '@/components/common/AppToolbar';
  import AppFooter from '@/components/common/AppFooter';
  import CustomizationMenu from "../components/customization/CustomizationMenu";


  export default {
    name: 'DefaultLayout',
    mixins: [
      MenuItems,
    ],
    components: {
      AppFooter,
      AppToolbar,
      AppSidebar,
      CustomizationMenu,
    },
    data() {
      return {
        sidebar: {
          collapsed: true,
        }
      }
    },
    created() {
      this.init();
    },
    async beforeRouteUpdate(to, from, next) {
      if(to.name !== 'payments-user' && this.$store.getters['account/hasOverduePayments']) {
        next({ name: 'payments-user' });
        await this.userHasOverduePayments();
        return;
      }
      next();
    },
    methods: {
      async init() {
        this.$vuetify.breakpoint.mdAndUp ? this.sidebar.collapsed = true : this.sidebar.collapsed = false;
        if(this.$route.name !== 'payments-user' && this.$store.getters['account/hasOverduePayments']) {
          await this.$router.push({name: 'payments-user'});
          await this.userHasOverduePayments();
        }
      },
      handleHamburgerClick() {
        this.sidebar.collapsed = !this.sidebar.collapsed;
      },
      async userHasOverduePayments() {
        const {$confirm} = this;
        await $confirm(
          this.$t('$messages.$overduePaymentsDialog.content'),
          {
            title: this.$t('$messages.$overduePaymentsDialog.title'),
            buttonTrueText: this.$t('$common.close'),
            buttonFalseText: '',
          });
      },
    },
  }
</script>
