<template>
  <v-alert
    class="notification-alert"
    v-bind="$attrs"
    v-on="$listeners"
    :color="color"
    :dark="mode === themeMode.DARK"
    :icon="icon"
    :border="border"
    :prominent="prominent"
    :min-height="minHeight"
    :max-width="maxWidth"
  >
    <slot></slot>
  </v-alert>
</template>
<script>
  import { MODE as themeMode } from '@/constants/theme'
  import { mapGetters } from "vuex";

  export default {
    name: 'NotificationAlert',
    props: {
      color: {
        type: String,
        default: 'primary',
      },
      icon: {
        type: String,
        default: 'mdi-bell-outline',
      },
      border: {
        type: String,
        default: 'left',
      },
      prominent: {
        type: Boolean,
        default: true,
      },
      maxWidth: {
        type: String,
        default: '500',
      },
      minHeight: {
        type: String,
        default: '120',
      }
    },
    data() {
      return {
        themeMode,
      };
    },
    computed: {
      ...mapGetters({
        mode: 'account/mode',
      }),
    }
  }
</script>
<style lang="scss">
  .notification-alert {
    .v-alert__content {
      min-height: 90px;
      word-break: break-all;
    }
    &.theme--light {
      color: #edf0f2;
    }
    .v-icon.theme--light {
      color: inherit;
    }
  }
</style>
