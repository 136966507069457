import icons from './icons'
import theme from './theme'
import toolbar from './toolbar'
import analytics from './analytics'
import navigation from './navigation'

export default {
  // icon libraries
  icons,

  // theme configs
  theme,

  // toolbar configs
  toolbar,

  // analytics configs
  analytics,

  // navigation configs
  navigation
}
