import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VBtn,{ref:"button",staticClass:"drawer-button",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.visible = true}}},[_c(VIcon,{staticClass:"fa-spin"},[_vm._v("mdi-cog-outline")])],1),_c(VNavigationDrawer,{attrs:{"color":"components","fixed":"","right":"","hide-overlay":"","temporary":"","width":"310"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticClass:"d-flex align-center pa-2"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('$customizationMenu.title')))]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.visible = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VDivider),_c(VTabs,{attrs:{"show-arrows":""},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},_vm._l((_vm.themeTabs),function(ref,index){
var title = ref.title;
var mode = ref.mode;
return _c(VTab,{key:index,attrs:{"href":("#" + mode)}},[_vm._v(" "+_vm._s(title)+" ")])}),1),_c(VTabsItems,{staticClass:"pa-2",model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},_vm._l((_vm.themeTabs),function(ref,index){
var colors = ref.colors;
var mode = ref.mode;
return _c(VTabItem,{key:index,attrs:{"value":mode,"eager":""}},[_c('div',{staticClass:"customization-menu__actions actions py-2 text-center"},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.handleSaveSettingsClick({ mode: mode, themeSettings: _vm.theme[mode] })}}},[_vm._v(" "+_vm._s(_vm.$t('$customizationMenu.saveSettings'))+" ")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){_vm.restoreDefaultTheme(
              {
                mode: mode,
                themeSettings: _vm.defaultThemeSettings[mode],
                successMessage: _vm.$t('$messages.$customizationMenu.settingsRestoredSuccessfully')
              }
            )}}},[_vm._v(" "+_vm._s(_vm.$t('$customizationMenu.restoreDefaultTheme'))+" ")])],1),_vm._l((colors),function(color){return [(_vm.initialized)?_c('customization-menu-color',{key:color,attrs:{"mode":mode,"theme":_vm.theme,"color":color}}):_vm._e()]})],2)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }