import Vue from 'vue';

const EventBus = new Vue();

export const emitNotificationId = (id) => {
  EventBus.$emit('notification-click', id);
};

export const emitProductsOnTypeChange = (products, type) => {
  EventBus.$emit('invoice-type-change', products, type);
};

export const emitPaymentStatusChange = () => {
  EventBus.$emit('payment-status-change');
};

export default EventBus;
