export const format = (value, currency = '') => {
  let valueString = value.toString().replace(',', '.');

  if (valueString.includes('.')) {
    const decimals = valueString.split('.')[1];
    if (decimals.length >= 2) {
      return `${valueString} ${currency}`.trim();
    }
  }
  return `${parseFloat(valueString).toFixed(2)} ${currency}`.trim();
}

export const formatCurrency = (value, currency = '') => {
  return `${new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value).replaceAll('.', ' ')} ${currency}`.trim();
}
