import Vue from 'vue';
import Router from 'vue-router';
import DefaultLayout from '@/layouts/DefaultLayout';
import AuthRoutes from './auth';
import PagesRoutes from './pages.routes';
import CommonRoutes from './common';

import AdminRoutes from './admin';
import UserRoutes from './user';
import GodRoutes from './god';


Vue.use(Router)

export const routes = [
  {
    path: '',
    component: DefaultLayout,
    meta: { auth: true },
    children: [
      ...AdminRoutes,
      ...UserRoutes,
      ...CommonRoutes,
      ...GodRoutes
    ],
  },
  ...AuthRoutes,
  ...PagesRoutes,
  {
    path: '*',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '@/pages/error/NotFoundPage.vue'),
    meta: {
      layout: 'error'
    }
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    }
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  return next()
})

router.afterEach((to, from) => {
})

export default router
