export const MODE = {
  LIGHT: 'light',
  DARK: 'dark',
}

export const THEME_COLORS = {
  DARK_MODE: {
    SUCCESS: '#4CAF50',
    WARNING: '#FFC107',
    ERROR: '#FF5252',
  },
  LIGHT_MODE: {
    SUCCESS: '#4CAF50',
    WARNING: '#FFC107',
    ERROR: '#FF5252',
  },
}

export const NAVIGATION = {
  HAMBURGER_COLOR: 'white',
  NOTIFICATIONS_ICON_COLOR: 'white',
  MODE_ICON_COLOR: 'white',
}
