<template>
  <v-app-bar
    id="app-bar"
    app
    :color="currentTheme.secondary"
    :light="themeMode === MODE.LIGHT"
    :dark="themeMode === MODE.DARK"
  >
    <v-card class="flex-grow-1 d-flex pa-0 ma-0" flat :color="currentTheme.secondary">
      <div class="d-flex flex-grow-1 align-center secondary">
        <v-app-bar-nav-icon class="mr-2" :color="NAVIGATION.HAMBURGER_COLOR" @click.stop="handleHamburgerClick"></v-app-bar-nav-icon>

        <v-img
          v-if="!isSidebarCollapsed"
          max-width="150"
          :src="image"
        />

        <v-spacer></v-spacer>
        <toolbar-company v-if="user.myCompanies && user.myCompanies.length > 1" />

        <span v-if="$vuetify.breakpoint.smAndUp && $isGranted('PERM_BUSINESS_USER_PAYMENTS')" class="mr-1 grey--text text--lighten-3" style="font-size: 0.913rem">{{$t('$menu.myBalance')}}: </span>
        <router-link :to="{ name: 'payments-user' }">
         <toolbar-balance v-if="$isGranted('PERM_BUSINESS_USER_PAYMENTS')"/>
        </router-link>

        <v-tooltip bottom v-if="$isGrantedAdminOr('PERM_OFFICE_WORKER_COMPANY_BALANCE_LIST')">
          <template v-slot:activator="{ on, attrs }">
           <div v-on="on">
             <router-link v-if="$isGrantedAdminOr('PERM_OFFICE_WORKER_COMPANY_BALANCE_LIST')"
                          :to="{ name: 'company-cart-admin' }" class="mr-2">

               <v-icon color="white">mdi-credit-card-outline</v-icon>
             </router-link>
           </div>
          </template>
          <span>{{ $t('$menu.$servicesAndPayments.$companyCart.title') }}</span>
        </v-tooltip>

        <toolbar-theme-mode v-if="$vuetify.breakpoint.smAndUp" />

        <toolbar-company-notifications v-if="$vuetify.breakpoint.smAndUp && user.companyData" />
        <toolbar-notifications v-if="$vuetify.breakpoint.smAndUp && !user.companyData" />
        <toolbar-user v-if="$vuetify.breakpoint.smAndUp" />
      </div>
    </v-card>
  </v-app-bar>
</template>
<script>
  import { mapGetters } from 'vuex';
  import ToolbarUser from "../toolbar/ToolbarUser";
  import ToolbarNotifications from "../toolbar/ToolbarNotifications";
  import ToolbarThemeMode from '../toolbar/ToolbarThemeMode';
  import { MODE } from '@/constants/theme';
  import { NAVIGATION } from '@/constants/theme';
  import ToolbarBalance from "@/components/toolbar/ToolbarBalance.vue";
  import ToolbarCompanyNotifications from "@/components/toolbar/ToolbarCompanyNotifications.vue";
  import ToolbarCompany from "@/components/toolbar/ToolbarCompany.vue";

  export default {
    name: 'AppToolbar',
    components: {
      ToolbarUser,
      ToolbarCompany,
      ToolbarNotifications,
      ToolbarCompanyNotifications,
      ToolbarThemeMode,
      ToolbarBalance
    },
    props: {
      isSidebarCollapsed: {
        type: Boolean,
        default: true,
      },
      image: {
        type: String,
        default: `/images/logo/${process.env.VUE_APP_LOGO_WHITE}`
      },
    },
    computed: {
      ...mapGetters({
        themeMode: 'account/mode',
        user: 'account/user',
      }),
      currentTheme() {
        return this.$vuetify.theme.currentTheme;
      },
    },
    data() {
      return {
        MODE,
        NAVIGATION,
      };
    },
    methods: {
      handleHamburgerClick(ev) {
        this.$emit('click', ev);
      },
    },
  }
</script>
