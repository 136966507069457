import Vue from 'vue';
import Vuex from 'vuex';

import AppModule from './app';
import auth from './auth';
import account from './account'
import ui from './ui'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app: AppModule,
    auth,
    account,
    ui
  }
})

export default store
