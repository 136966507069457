<template>
  <v-card tile>
    <v-toolbar
      flat
      dark
      class="create-edit-invoice-dialog__toolbar px-1"
      color="primary"
    >
      <v-toolbar-title>{{ form.title }}</v-toolbar-title>
      <v-spacer/>
      <v-btn
        v-if="form.hasCloseButton"
        icon
        dark
        @click="handlePending"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pt-2">
      <div v-html="form.content"></div>
      <v-textarea :value="form.note" dense outlined v-if="form.hasTextArea" @input="value => handleFieldChange('note', value)"/>
    </v-card-text>
    <v-card-actions class="justify-end d-inline-flex flex-wrap card-actions" style="gap: 8px">
      <v-btn v-if="form.hasRejectButton" class="btn-fit" @click="handleRejectClick">
        {{ form.rejectText }}
      </v-btn>
      <v-btn
        class="btn-fit"
        color="primary"
        @click="handleSubmitClick"
      >
        {{ form.submitText }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import {STATEMENT_ANSWER} from "@/constants/statement-answer.enum";

export default {
  name: 'StatementBody',
  components: {},
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async handleSubmitClick() {
      this.handleFieldChange('answer', STATEMENT_ANSWER.ACCEPT)
      this.$emit('close');
    },
    async handleRejectClick() {
      this.handleFieldChange('answer', STATEMENT_ANSWER.REJECT)
      this.$emit('close');
    },
    handlePending() {
      this.handleFieldChange('answer', STATEMENT_ANSWER.PENDING)
      this.$emit('close');
    },
    handleFieldChange(field, value) {
      const { form } = this;
      this.$emit('update:form', Object.assign(form, { [field]: value }))
    },
  },
}
</script>
<style lang="scss" scoped>
.create-edit-invoice-dialog {
  &__toolbar {
    max-height: 70px;
  }

  &__switch-wrapper {
    display: inline-block;
  }
}
.btn-fit {
  height: auto!important;
  min-height: 36px!important;
}
::v-deep .v-btn__content {
  white-space: pre-wrap !important;
  padding: 5px;
  max-width: 200px;
}

@media (max-width: 425px) {
  .card-actions{
    justify-content: center!important;

    .v-btn:first-child {
      order: 2; /* Ustawia pierwszy przycisk jako drugi */
    }

    .v-btn:last-child {
      order: 1; /* Ustawia drugi przycisk jako pierwszy */
    }
  }
}
</style>
