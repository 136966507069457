<template>
  <div>
    <v-btn
      ref="button"
      class="drawer-button"
      color="primary"
      dark
      @click="visible = true"
    >
      <v-icon class="fa-spin">mdi-cog-outline</v-icon>
    </v-btn>

    <v-navigation-drawer
      v-model="visible"
      color="components"
      fixed
      right
      hide-overlay
      temporary
      width="310"
    >
      <div class="d-flex align-center pa-2">
        <div class="title">{{ $t('$customizationMenu.title') }}</div>
        <v-spacer></v-spacer>
        <v-btn icon @click="visible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-divider></v-divider>

      <v-tabs
        v-model="currentTab"
        show-arrows
      >
        <v-tab v-for="({ title, mode }, index) in themeTabs" :key="index" :href="`#${mode}`">
          {{ title }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="currentTab" class="pa-2">
        <v-tab-item
          v-for="({ colors, mode }, index) in themeTabs"
          :key="index"
          :value="mode"
          eager
        >
          <div class="customization-menu__actions actions py-2 text-center">
            <v-btn
              color="primary"
              @click="handleSaveSettingsClick({ mode, themeSettings: theme[mode] })"
            >
              {{ $t('$customizationMenu.saveSettings') }}
            </v-btn>
            <v-btn
              color="primary"
              @click="restoreDefaultTheme(
                {
                  mode,
                  themeSettings: defaultThemeSettings[mode],
                  successMessage: $t('$messages.$customizationMenu.settingsRestoredSuccessfully')
                }
              )"
            >
              {{ $t('$customizationMenu.restoreDefaultTheme') }}
            </v-btn>
          </div>

          <template v-for="color in colors">
            <customization-menu-color
              v-if="initialized"
              :key="color"
              :mode="mode"
              :theme="theme"
              :color="color"
            />
          </template>
        </v-tab-item>
      </v-tabs-items>
    </v-navigation-drawer>
  </div>
</template>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex'
  import CustomizationMenuColor from './CustomizationMenuColor';
  import { MODE } from '@/constants/theme';
  import themeSettings from "@/configs/theme";

  export default {
    components: {
      CustomizationMenuColor
    },
    data() {
      return {
        initialized: false,
        visible: false,
        timeout: null,
        defaultThemeSettings: themeSettings.defaultThemeSettings,
        theme: {},
        themeTabs: [
          {
            title: this.$t('$customizationMenu.$theme.light'),
            mode: MODE.LIGHT,
            colors: [
              'primary',
              'secondary',
              'background',
              'components',
            ],
          },
          {
            title: this.$t('$customizationMenu.$theme.dark'),
            mode: MODE.DARK,
            colors: [
              'primary',
              'secondary',
              'background',
              'components',
            ],
          }
        ],
        currentTab: 'light',
      }
    },
    mounted() {
      this.init()
    },
    beforeDestroy() {
      if (this.timeout) clearTimeout(this.timeout)
    },
    computed: {
      ...mapState({
        settings: state => state.app.settings,
      }),
      ...mapGetters({
        currentThemeMode: 'account/mode',
      }),
    },
    methods: {
      ...mapActions('app', {
        saveModeSettings: 'saveModeSettings',
        appInit: 'init',
        showSuccess: 'showSuccess',
      }),
      init() {
        if (this.initialized) return;
        this.initialized = false;
        const { animate, theme, settings: { darkMode, lightMode } } = this;
        animate()
        this.currentTab = this.currentThemeMode;
        this.theme = Object.assign(this.theme, this.defaultThemeSettings);
        Object.assign(theme, { light: lightMode, dark: darkMode });
        this.initialized = true;
      },
      animate() {
        if (this.timeout) clearTimeout(this.timeout)

        const time = (Math.floor(Math.random() * 10 + 1) + 10) * 1000

        this.timeout = setTimeout(() => {
          this.$animate(this.$refs.button.$el, 'bounce')
          this.animate()
        }, time)
      },
      async handleSaveSettingsClick(settings) {
        const { mode, themeSettings, successMessage = this.$t('$messages.settingsSavedSuccessfully') } = settings;
        const { saveModeSettings, appInit, showSuccess } = this;

        this.isLoading = true;
        try {
          await saveModeSettings({ mode, settings: themeSettings });
          await appInit();
          showSuccess(successMessage);
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      },
      async restoreDefaultTheme(settings) {
        const { mode, themeSettings } = settings;
        const { theme, handleSaveSettingsClick } = this;
        Object.assign(theme[mode], themeSettings);
        handleSaveSettingsClick(settings);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .customization-menu {
    &__actions {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
    }
  }
  .drawer-button {
    position: fixed;
    top: 340px;
    right: -20px;
    z-index: 6;

    .v-icon {
      margin-left: -18px;
      font-size: 1.3rem;
    }
  }
</style>
