import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$isGranted(_vm.menuItem.perm))?_c('div',[(!_vm.menuItem.items)?_c(VListItem,{attrs:{"input-value":_vm.menuItem.value,"to":_vm.menuItem.routeName ? { name: _vm.menuItem.routeName } : '',"href":_vm.menuItem.link ? _vm.menuItem.link : '',"target":_vm.menuItem.link ? '_blank' : '',"exact":_vm.menuItem.exact,"disabled":_vm.menuItem.disabled,"active-class":"primary--text","link":""}},[_c(VListItemIcon,[_c(VIcon,{class:{ 'grey--text': _vm.menuItem.disabled },attrs:{"small":_vm.small}},[_vm._v(" "+_vm._s(_vm.menuItem.icon || 'mdi-circle-medium')+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.menuItem.key ? _vm.$t(_vm.menuItem.key) : _vm.menuItem.text)+" ")])],1)],1):_c(VListGroup,{attrs:{"value":_vm.menuItem.regex ? _vm.menuItem.regex.test(_vm.$route.path) : false,"disabled":_vm.menuItem.disabled,"sub-group":_vm.subgroup,"to":_vm.menuItem.link,"link":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [(!_vm.subgroup)?_c(VListItemIcon,[_c(VIcon,{attrs:{"small":_vm.small}},[_vm._v(_vm._s(_vm.menuItem.icon || 'mdi-circle-medium'))])],1):_vm._e(),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.menuItem.key ? _vm.$t(_vm.menuItem.key) : _vm.menuItem.text)+" ")])],1)]},proxy:true}],null,false,1627899138)},[_vm._t("default")],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }