import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{attrs:{"id":"app-bar","app":"","color":_vm.currentTheme.secondary,"light":_vm.themeMode === _vm.MODE.LIGHT,"dark":_vm.themeMode === _vm.MODE.DARK}},[_c(VCard,{staticClass:"flex-grow-1 d-flex pa-0 ma-0",attrs:{"flat":"","color":_vm.currentTheme.secondary}},[_c('div',{staticClass:"d-flex flex-grow-1 align-center secondary"},[_c(VAppBarNavIcon,{staticClass:"mr-2",attrs:{"color":_vm.NAVIGATION.HAMBURGER_COLOR},on:{"click":function($event){$event.stopPropagation();return _vm.handleHamburgerClick.apply(null, arguments)}}}),(!_vm.isSidebarCollapsed)?_c(VImg,{attrs:{"max-width":"150","src":_vm.image}}):_vm._e(),_c(VSpacer),(_vm.user.myCompanies && _vm.user.myCompanies.length > 1)?_c('toolbar-company'):_vm._e(),(_vm.$vuetify.breakpoint.smAndUp && _vm.$isGranted('PERM_BUSINESS_USER_PAYMENTS'))?_c('span',{staticClass:"mr-1 grey--text text--lighten-3",staticStyle:{"font-size":"0.913rem"}},[_vm._v(_vm._s(_vm.$t('$menu.myBalance'))+": ")]):_vm._e(),_c('router-link',{attrs:{"to":{ name: 'payments-user' }}},[(_vm.$isGranted('PERM_BUSINESS_USER_PAYMENTS'))?_c('toolbar-balance'):_vm._e()],1),(_vm.$isGrantedAdminOr('PERM_OFFICE_WORKER_COMPANY_BALANCE_LIST'))?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({},on),[(_vm.$isGrantedAdminOr('PERM_OFFICE_WORKER_COMPANY_BALANCE_LIST'))?_c('router-link',{staticClass:"mr-2",attrs:{"to":{ name: 'company-cart-admin' }}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-credit-card-outline")])],1):_vm._e()],1)]}}],null,false,2911379296)},[_c('span',[_vm._v(_vm._s(_vm.$t('$menu.$servicesAndPayments.$companyCart.title')))])]):_vm._e(),(_vm.$vuetify.breakpoint.smAndUp)?_c('toolbar-theme-mode'):_vm._e(),(_vm.$vuetify.breakpoint.smAndUp && _vm.user.companyData)?_c('toolbar-company-notifications'):_vm._e(),(_vm.$vuetify.breakpoint.smAndUp && !_vm.user.companyData)?_c('toolbar-notifications'):_vm._e(),(_vm.$vuetify.breakpoint.smAndUp)?_c('toolbar-user'):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }