import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"notifications-list",attrs:{"color":"components"}},[_c(VSubheader,{staticClass:"pa-2 font-weight-bold"},[_vm._v(_vm._s(_vm.$t('$menu.$myNotifications.title')))]),(_vm.notifications.length)?_c(VList,{attrs:{"three-line":"","dense":"","max-width":"400","color":"components"}},_vm._l((_vm.notifications),function(item,index){return _c('div',{key:index},[(index > 0 && index < _vm.notifications.length)?_c(VDivider,{attrs:{"inset":""}}):_vm._e(),_c(VListItem,{staticClass:"d-flex flex-wrap",on:{"click":function($event){return _vm.handleNotificationClick(item.id)}}},[(_vm.$vuetify.breakpoint.smAndUp)?_c(VListItemAvatar,{attrs:{"size":"24","color":item.color}},[_c(VIcon,{attrs:{"dark":_vm.themeMode === _vm.MODE.DARK,"size":"24"}},[_vm._v(" mdi-email-open-outline ")])],1):_vm._e(),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-truncate subtitle-1",domProps:{"textContent":_vm._s(item.subject)}}),_c(VListItemSubtitle,{staticClass:"text-truncate subtitle-2",domProps:{"innerHTML":_vm._s(_vm.formatContent(item.content))}}),_c(VListItemSubtitle,{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.format(_vm.parseISO(item.createdAt.date), 'dd/MM/yyyy HH:mm'))+" ")])],1)],1)],1)}),0):_c(VCardText,[_c('p',[_vm._v(_vm._s(_vm.$t('$menu.$myNotifications.$dropdown.currentlyNoNewNotifications')))])]),_c(VCardActions,{staticClass:"justify-center py-2"},[_c(VBtn,{attrs:{"small":""},on:{"click":_vm.seeAllNotificationsClick}},[_vm._v(_vm._s(_vm.$t('$menu.$myNotifications.$dropdown.seeAll')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }