export default {
  methods: {
    async handleLogout() {
      const { $store, $router } = this;
      localStorage.removeItem('switch_user')
      await $store.dispatch('auth/logout');
      await $router.push({name: 'login'});
    },
    handleLogoutFromUserAccount() {
      this.$store.commit('account/SET_USER', {})
      localStorage.removeItem('switch_user')
      window.location.href = '/'
    }
  }
}
