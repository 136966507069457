import Vuetify from '@/plugins/vuetify';
import { MODE as themeMode } from '../../constants/theme';

const baseState = {
  user: {
    email: null,
    avatar: null,
  },
  mode: 'light',
  company: {}
};

const SET_USER = (state, user) => {
  state.user = user;
};

const SET_USER_DEFAULT_ADDRESS = (state, address) => {
  state.defaultAddress = address;
}

const SET_UNREAD_NOTIFICATIONS_COUNT = (state, count) => {
  state.user.unreadNotifications = count;
}
const SET_COMPANY_UNREAD_NOTIFICATIONS_COUNT = (state, count) => {
  state.user.companyData.unreadNotifications = count;
}

const SET_MODE = (state, mode) => {
  state.mode = mode;
  Vuetify.framework.theme.dark = state.mode === themeMode.DARK;
}

const SET_COMPANY = (state, company) => {
  state.company = company;
}

const CLEAR = state => {
  state.user = Object.assign({}, baseState.user);
};

const IS_CHANGE_PROFILE_IMAGE_LOADING = (state, isLoading) => {
  state.isChangeProfileImageLoading = isLoading;
}

export default {
  SET_USER,
  SET_USER_DEFAULT_ADDRESS,
  SET_UNREAD_NOTIFICATIONS_COUNT,
  SET_COMPANY_UNREAD_NOTIFICATIONS_COUNT,
  SET_MODE,
  CLEAR,
  IS_CHANGE_PROFILE_IMAGE_LOADING,
  SET_COMPANY
}
