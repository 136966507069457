<template>
  <v-menu offset-y left transition="slide-y-transition">
    <template v-slot:activator="{ on }">
      <div class=" content-text-container cursor-pointer" v-on="on">
        <v-icon class="pr-1 pl-1" color="grey lighten-3">mdi-autorenew</v-icon>
        <span v-if="$vuetify.breakpoint.smAndUp" class="grey--text text--lighten-3 pr-2">{{ currentCompany.name }}</span>
      </div>
    </template>

    <!-- company menu list -->
    <v-list
      dense
      nav
      color="components"
    >
      <template v-for="(item, index) in myCompanies">
        <v-list-item
          :key="index"
          :disabled="item.id === user.currentCompanyId"
          @click="handleChangeCompanyClick(item.id)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.name}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
  import config from '../../configs'
  import Logout from '@/mixins/logout';
  import { mapState } from "vuex";

  export default {
    mixins: [
      Logout,
    ],
    data() {
      return {
        menu: config.toolbar.user,
      };
    },
    computed: {
      ...mapState({
        user: state => state.account.user,
      }),
      currentCompany() {
        return this.user.myCompanies.find(company => company.id === this.user.currentCompanyId);
      },
      myCompanies() {
        const targetObject = this.user.myCompanies.find(item => item.id === this.user.currentCompanyId);
        return [targetObject, ...this.user.myCompanies.filter(item => item.id !== this.user.currentCompanyId)];
      },
    },
    methods:{
      handleChangeCompanyClick(companyId) {
        console.log(companyId);
        this.$store.dispatch('account/changeCompany', companyId);
      },
    }
  }
</script>
<style lang="scss" scoped>
  .content-text-container {
    display: flex;
    align-items: center;
    font-size: 0.913rem;
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 300px;
    }
  }
</style>
