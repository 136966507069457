const SET_ACCOUNTING_INFO = (state, value) => {
  state.displayAccountingInfo = value;
};

const SET_AUTH_INFO = (state, value) => {
  state.displayAuthInfo = value;
}

export default {
  SET_ACCOUNTING_INFO,
  SET_AUTH_INFO,
}
