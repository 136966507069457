<template>
  <v-menu
    v-model="visible"
    offset-y
    offset-x
    offset-overflow
    left
    bottom
    attach="#app-bar"
    transition="slide-y-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        :content="unreadNotifications"
        :value="unreadNotifications"
        :light="themeMode === MODE.LIGHT"
        :dark="themeMode === MODE.DARK"
        color="primary"
        offset-x="22"
        offset-y="22"
        overlap
      >
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          @click.stop="handleNotificationsBellClick"
        >
          <v-icon :color="NAVIGATION.NOTIFICATIONS_ICON_COLOR">mdi-bell-outline</v-icon>
        </v-btn>
      </v-badge>
    </template>

    <!-- notifications dropdown card -->
    <notifications-list :notifications="notifications" />
  </v-menu>
</template>

<script>
  import { mapGetters } from "vuex";
  import { MODE } from '@/constants/theme';
  import { NAVIGATION } from "@/constants/theme";
  import NotificationsList from "@/components/notifications/NotificationsList";
  import { getCompanyUnreadNotifications, markCompanyNotificationsAsRead } from "@/api/my-notifications";

  export default {
    name: 'ToolbarNotifications',
    components: {
      NotificationsList
    },
    data() {
      return {
        visible: false,
        notifications: [],
        notificationsLimit: 5,
        MODE,
        NAVIGATION,
      }
    },
    computed: {
      ...mapGetters({
        themeMode: 'account/mode',
        unreadNotifications: 'account/unreadCompanyNotifications',
      }),
    },
    methods: {
      async handleNotificationsBellClick() {
        if (this.visible) {
          return;
        }
        this.isLoading = true;
        try {
          this.notifications = await getCompanyUnreadNotifications({ limit: this.notificationsLimit });
          await markCompanyNotificationsAsRead();
          this.$store.commit('account/SET_COMPANY_UNREAD_NOTIFICATIONS_COUNT', 0)
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      },
    },
  }
</script>
