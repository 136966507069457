import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":"","offset-x":"","offset-overflow":"","left":"","bottom":"","attach":"#app-bar","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBadge,{attrs:{"content":_vm.unreadNotifications,"value":_vm.unreadNotifications,"light":_vm.themeMode === _vm.MODE.LIGHT,"dark":_vm.themeMode === _vm.MODE.DARK,"color":"primary","offset-x":"22","offset-y":"22","overlap":""}},[_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleNotificationsBellClick.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":_vm.NAVIGATION.NOTIFICATIONS_ICON_COLOR}},[_vm._v("mdi-bell-outline")])],1)],1)]}}]),model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('notifications-list',{attrs:{"notifications":_vm.notifications}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }