export default [
  {
    path: '/',
    name: 'dashboard',
    meta: { role: 'ROLE_USER' },
    component: () => import(/* webpackChunkName: "information" */ '@/pages/information/Index.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    meta: { role: 'ROLE_USER' },
    component: () => import(/* webpackChunkName: "profile" */ '@/pages/profile/view/Index.vue')
  },
  {
    path: '/tickets/ticket-center/show/:id',
    name: 'show-ticket',
    meta: { role: 'ROLE_USER' },
    props: route => ({
      id: route.params.id,
    }),
    component: () => import(/* webpackChunkName: "ticket-center-id" */ '@/pages/ticket-center/Show.vue'),
  },
]
