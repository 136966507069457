import configs from '../../configs';
import actions from './actions';
import mutations from './mutations';
import getters from "./getters";

const { theme  } = configs

// state initial values
const state = {
  settings: {
    ...theme
  },
  language: {
    locale: null,
  },
  toast: {
    show: false,
    color: 'black',
    message: '',
    timeout: 3000
  },
  loader: {
    show: false,
    message: '',
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
