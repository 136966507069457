import { VApp } from 'vuetify/lib/components/VApp';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,{style:({ background: _vm.$vuetify.theme.themes[_vm.mode].background })},[(_vm.isRouterLoaded)?[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view')],1)]:_vm._e(),_c('notifications',{scopedSlots:_vm._u([{key:"body",fn:function(ref){
var item = ref.item;
var close = ref.close;
return [_c('notification-alert',{on:{"click":function($event){return _vm.handleNotificationClick(item.data.notificationId)}}},[_c('h3',{staticClass:"mb-1 line-clamp break-word"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"line-clamp"},[_c('p',{domProps:{"innerHTML":_vm._s(item.text)}})])])]}}])}),_c(VSnackbar,_vm._b({attrs:{"data-cy":("alert-" + (_vm.toast.color)),"timeout":_vm.toast.timeout,"dark":_vm.mode === _vm.themeMode.DARK,"color":_vm.toast.color,"top":""},model:{value:(_vm.toast.show),callback:function ($$v) {_vm.$set(_vm.toast, "show", $$v)},expression:"toast.show"}},'v-snackbar',_vm.toast.props,false),[_vm._v(" "+_vm._s(_vm.toast.message)+" ")]),_c(VOverlay,{attrs:{"value":_vm.loader.show,"z-index":"8"}},[_c('div',{staticClass:"d-flex flex-column align-center justify-center"},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":_vm.loaderSpinnerSize}}),(_vm.loader.message)?_c(VSubheader,{staticClass:"subtitle-1"},[_vm._v(" "+_vm._s(_vm.loader.message)+" ")]):_vm._e()],1)]),(_vm.statement)?_c('statement-dialog',{attrs:{"form":_vm.statement,"handler":_vm.handleSubmitClick,"visible":_vm.statementVisible},on:{"update:visible":function($event){_vm.statementVisible=$event}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }