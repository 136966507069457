import axios from 'axios';

export async function updateLightModeSettings(payload) {
  const { data } = await axios.patch('/admin/app-settings/light-mode', payload);
  return data;
}

export async function updateDarkModeSettings(payload) {
  const { data } = await axios.patch(`/admin/app-settings/dark-mode`, payload);
  return data;
}

export async function updateOfficeInformation(payload) {
  const { data } = await axios.patch(`/admin/app-settings/office-info`, payload);
  return data;
}
