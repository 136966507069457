<template>
  <v-dialog
    v-model="visible"
    scrollable
    :max-width="650"
    persistent
  >
    <statement-body :form.sync="form" @close="handleSubmitClick" />
  </v-dialog>
</template>

<script>

import StatementBody from "@/components/statements/StatementBody.vue";

export default {
  name: 'StatementDialog',
  components: {StatementBody},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      required: true,
    },
    handler: Function,
  },
  data() {
    return {};
  },

  methods: {
    async handleSubmitClick() {
      if (this.isLoading) {
        return;
      }

      const {form} = this;
      this.isLoading = true;
      try {
        await this.handler(form);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  }
}
</script>
