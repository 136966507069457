import { MODE } from "@/constants/theme";

export default {
  mode: '',
  darkMode: {},
  lightMode: {},
  defaultThemeSettings: {
    [MODE.LIGHT]: {
      primary: '#F21265',
      secondary: '#1A3668',
      background: '#f2f5f8',
      components: '#FFFFFF'
    },
    [MODE.DARK]: {
      primary: '#385EA2',
      secondary: '#05090c',
      background: '#111b27',
      components: '#05090c'
    },
  }
}
