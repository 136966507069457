<template>
  <v-menu
    offset-y
    left
    transition="slide-y-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn color="primary" class="mr-2" v-on="on" small>{{ `${formattedBalance} ${currency}` }}</v-btn>
    </template>
  </v-menu>
</template>

<script>

import { mapGetters } from "vuex";
import { MODE } from "@/constants/theme";
import { formatCurrency } from "@/helpers/numbers";

export default {
  inheritAttrs: false,
  props: {
    currency: {
      type: String,
      default: 'PLN'
    }
  },
  data() {
    return {}
  },
  computed: {
    MODE() {
      return MODE
    },
    ...mapGetters({
      themeMode: 'account/mode',
      balance: 'account/balance',
    }),
    formattedBalance() {
      return formatCurrency(this.balance > 0 ? -this.balance : Math.abs(this.balance));
    }
  },
}
</script>
