import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"tile":""}},[_c(VToolbar,{staticClass:"create-edit-invoice-dialog__toolbar px-1",attrs:{"flat":"","dark":"","color":"primary"}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.form.title))]),_c(VSpacer),(_vm.form.hasCloseButton)?_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":_vm.handlePending}},[_c(VIcon,[_vm._v("mdi-close")])],1):_vm._e()],1),_c(VCardText,{staticClass:"pt-2"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.form.content)}}),(_vm.form.hasTextArea)?_c(VTextarea,{attrs:{"value":_vm.form.note,"dense":"","outlined":""},on:{"input":function (value) { return _vm.handleFieldChange('note', value); }}}):_vm._e()],1),_c(VCardActions,{staticClass:"justify-end d-inline-flex flex-wrap card-actions",staticStyle:{"gap":"8px"}},[(_vm.form.hasRejectButton)?_c(VBtn,{staticClass:"btn-fit",on:{"click":_vm.handleRejectClick}},[_vm._v(" "+_vm._s(_vm.form.rejectText)+" ")]):_vm._e(),_c(VBtn,{staticClass:"btn-fit",attrs:{"color":"primary"},on:{"click":_vm.handleSubmitClick}},[_vm._v(" "+_vm._s(_vm.form.submitText)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }