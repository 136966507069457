import axios from "axios";

export async function archiveNotification(id) {
  const { data } = await axios.delete(`/my-notifications/${id}`);
  return data;
}

export async function archiveCompanyNotification(id) {
  const { data } = await axios.delete(`/my-notifications/companies/${id}`);
  return data;
}
export async function getCompanyUnreadNotifications(params) {
  const { data } = await axios.get(`/my-notifications/companies`, { params });
  return data;
}

export async function markCompanyNotificationsAsRead() {
  const { data } = await axios.patch(`/my-notifications/companies/read`);
  return data;
}
