import Vuetify from '../../plugins/vuetify'

export default {
  SET_LANGUAGE: (state, language) => {
    state.language = language;
  },
  SET_SETTINGS: (state, settings) => {
    const { darkMode, lightMode } = settings;

    state.settings = settings;
    Object.assign(Vuetify.framework.theme.themes, { light: lightMode, dark: darkMode });
  },
  SHOW_TOAST: (state, toast) => {
    const { color, timeout, message } = toast

    state.toast = {
      message,
      color,
      timeout,
      show: true
    }
  },
  HIDE_TOAST: state => {
    state.toast.show = false
  },
  SHOW_LOADER: (state, loader) => {
    const { message } = loader

    state.loader = {
      message,
      show: true
    }
  },
  HIDE_LOADER: state => {
    state.loader.show = false
  },
  RESET_TOAST: state => {
    state.toast = {
      show: false,
      color: 'black',
      message: '',
      timeout: 3000
    }
  },
}
