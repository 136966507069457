import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const initialState = {
  user: {
    email: null,
    avatar: null,
  },
  company: {},
  defaultAddress: null,
  mode: 'light',
  isChangeProfileImageLoading: false
};

export default {
  namespaced: true,
  state: () => initialState,
  getters,
  actions,
  mutations,
}
