import { INCOME_TAX_VAT } from "@/constants/user";

export default {
  user: state => state.user ? state.user : null,
  company: state => state.company ? state.company : null,
  userDefaultAddress: state => state.defaultAddress ? state.defaultAddress : null,
  unreadNotifications: state => state.user && state.user.unreadNotifications ? state.user.unreadNotifications : null,
  unreadCompanyNotifications: state => state.user && state.user.companyData ? state.user.companyData.unreadNotifications : null,
  balance: state => state.user && state.user.companyData && state.user.companyData.balance ? state.user.companyData.balance: 0,
  mode: state => state.mode,
  bankAccounts: state => state.user && state.user.bankAccounts ? state.user.bankAccounts : null,
  additionalPlacesOfBusiness: state => state.user && state.user.additionalPlacesOfBusiness ? state.user.additionalPlacesOfBusiness : null,
  invoicePaymentDays: state => state.user && state.user.companyData ? state.user.companyData.paymentDays : null,
  isChangeProfileImageLoading: state => state.isChangeProfileImageLoading,
  isUserFreeOfVat: state => state.user && state.user.companyData && state.user.companyData.incomeTaxVat === INCOME_TAX_VAT.FREE_OF_VAT,
  myCompanies: state => state.user && state.user.myCompanies ? state.user.myCompanies : [],
  currentCompanyId: state => state.user && state.user.currentCompanyId ? state.user.currentCompanyId : null,
  hasOverduePayments: state => state.user && state.user.companyData && state.user.companyData.hasOverduePayments ? state.user.companyData.hasOverduePayments : false,
}
