import axios from 'axios';

export async function getUnreadNotifications(params) {
  const { data } = await axios.get(`/my-notifications/`, { params });
  return data;
}

export async function markNotificationsAsRead() {
  const { data } = await axios.patch(`/my-notifications/read`);
  return data;
}
