import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VNavigationDrawer,{staticClass:"elevation-1",attrs:{"data-cy":"app-sidebar","value":_vm.collapsed,"app":"","floating":"","right":_vm.$vuetify.rtl,"color":_vm.currentTheme.components,"light":_vm.mode === _vm.themeMode.LIGHT,"dark":_vm.mode === _vm.themeMode.DARK},on:{"input":_vm.handleSidebarCollapse},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('div',{staticClass:"pa-2"},[_c('router-link',{attrs:{"to":{ name: 'dashboard' }}},[_c(VImg,{staticClass:"my-4 mx-auto",attrs:{"max-height":"150","max-width":"400","contain":"","src":_vm.image}})],1)],1)]},proxy:true},{key:"append",fn:function(){return [_c('div',{staticClass:"pa-1 text-center"},_vm._l((_vm.footerItems),function(item,index){return _c(VBtn,{key:index,attrs:{"href":item.href,"target":item.target,"small":"","text":""}},[_vm._v(" "+_vm._s(item.key ? _vm.$t(item.key) : item.text)+" ")])}),1),(!_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"pa-2 pt-1 text-center"},[_c(VBtn,{attrs:{"color":"primary","small":""},on:{"click":_vm.handleLogout}},[_vm._v(" "+_vm._s(_vm.$t('$menu.logout'))+" ")])],1):_vm._e(),_c('div',{staticClass:"pa-2 pt-1 text-center"},[_c('ValidationObserver',{ref:"vatObserver",attrs:{"slim":""}},[_c('ValidationProvider',{attrs:{"name":"nip","rules":"numeric|nip|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{staticClass:"mb-2",attrs:{"type":"text","label":"NIP","error-messages":errors[0],"name":"nip"},model:{value:(_vm.form.nip),callback:function ($$v) {_vm.$set(_vm.form, "nip", $$v)},expression:"form.nip"}})]}}])})],1),_c(VBtn,{staticClass:"check-vat-button",attrs:{"block":"","color":"primary"},on:{"click":_vm.checkVat}},[_vm._v(" "+_vm._s(_vm.$t('$menu.checkVat'))+" ")])],1)]},proxy:true}])},[(!_vm.$vuetify.breakpoint.smAndUp)?_c('toolbar-theme-mode',{staticClass:"pa-2",attrs:{"color":"primary"}}):_vm._e(),_c('nav-menu',{attrs:{"menu":_vm.menuItems}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }