import { checkIsActiveVatPayerApiMethod } from '@/api/mf-api';

export default {
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    async checkIsActiveVatPayer(nip) {
      if (!nip) {
        return;
      }

      const { $store } = this;
      this.isLoading = true;
      try {
        const { result } = await checkIsActiveVatPayerApiMethod(nip);
        if (result.subject && result.subject.statusVat === "Czynny") {
          await $store.dispatch('app/showSuccess', this.$t('$messages.$mf.verified'));
        } else {
          await $store.dispatch('app/showError', { message: this.$t('$messages.$mf.notVerified') });
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false;
      }
    },
  }
}
