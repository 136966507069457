import axios from 'axios';

export async function initApp() {
  const { data } = await axios.get('/app-init');
  return data;
}

export async function userAppInit() {
  const { data } = await axios.get('/user-app-init');
  return data;
}

export async function companyAppInit() {
  const { data } = await axios.get('/company-app-init');
  return data;
}
