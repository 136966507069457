<template>
  <v-switch
    class="theme-mode-switcher"
    v-model="themeMode"
    :prepend-icon="icon"
    true-value="light"
    false-value="dark"
    :color="color"
    inset
    @change="handleThemeSwitch"
  />
</template>
<script>
  import { mapActions, mapState, mapGetters } from "vuex";
  import { MODE } from '@/constants/theme';
  import { NAVIGATION } from "@/constants/theme";

  export default {
    name: 'ToolbarThemeMode',
    props: {
      color: {
        type: String,
        default: NAVIGATION.MODE_ICON_COLOR,
      }
    },
    data() {
      return {
        themeMode: MODE.LIGHT,
        NAVIGATION,
      };
    },
    computed: {
      ...mapState({
        theme: state => state.app.settings,
      }),
      ...mapGetters({
        currentThemeMode: 'account/mode',
      }),
      icon() {
        return this.currentThemeMode === MODE.LIGHT ? 'mdi-white-balance-sunny' : 'mdi-weather-night';
      },
    },
    mounted() {
      this.initComponent();
    },
    methods: {
      ...mapActions('account', {
        changeUserSettings: 'changeUserSettings',
        appUserInit: 'init',
      }),
      async initComponent() {
        const { currentThemeMode } = this;
        this.themeMode = currentThemeMode;
      },
      async handleThemeSwitch(mode) {
        const { changeUserSettings } = this;
        await changeUserSettings({ mode });
      },
    }
  }
</script>
<style lang="scss">
  .theme-mode-switcher {
    & .v-messages {
      display: none;
    }
    .v-input__slot {
      margin-bottom: 0px;
    }
  }
</style>
