const isAuthenticated = state => state.ready && !!state.user;
const isGranted = state => role => {
  const userRoles = state.roles || [];
  let roles = [];

  if (typeof role === 'string') {
    roles = [role];
  }

  if (Array.isArray(role)) {
    roles = role;
  }

  return userRoles.some(x => roles.includes(x));
};

const isGrantedAdminOr = state => role => {
  const userRoles = state.roles || [];
  let roles = [];

  if (typeof role === 'string') {
    roles = [role];
  }

  if (Array.isArray(role)) {
    roles = role;
  }

  if (userRoles.indexOf('ROLE_ADMIN') >= 0) {
    return true;
  }

  return userRoles.some(x => roles.includes(x));
};
const isGrantedByToken = state => role => {
  const userRoles = state.tokenRoles || [];
  let roles = [];

  if (typeof role === 'string') {
    roles = [role];
  }

  if (Array.isArray(role)) {
    roles = role;
  }

  return userRoles.some(x => roles.includes(x));
};

export default {
  isAuthenticated,
  isGranted,
  isGrantedAdminOr,
  isGrantedByToken
}
