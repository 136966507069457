import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":"","left":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"content-text-container cursor-pointer"},on),[_c(VIcon,{staticClass:"pr-1 pl-1",attrs:{"color":"grey lighten-3"}},[_vm._v("mdi-autorenew")]),(_vm.$vuetify.breakpoint.smAndUp)?_c('span',{staticClass:"grey--text text--lighten-3 pr-2"},[_vm._v(_vm._s(_vm.currentCompany.name))]):_vm._e()],1)]}}])},[_c(VList,{attrs:{"dense":"","nav":"","color":"components"}},[_vm._l((_vm.myCompanies),function(item,index){return [_c(VListItem,{key:index,attrs:{"disabled":item.id === _vm.user.currentCompanyId},on:{"click":function($event){return _vm.handleChangeCompanyClick(item.id)}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.name))])],1)],1)]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }