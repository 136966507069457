import Confirm from "@/components/common/Modal/ConfirmModal";

function Install (Vue, options = {}) {
  Vue.prototype.$scrollTo = function(selector) {
    const element = this.$el.querySelector(selector);
    if(!element) return;

    setTimeout(() => {
      element.scrollIntoView({ behavior: 'smooth' })
    }, 1)
  }
}

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(Install)
}

export default Install
