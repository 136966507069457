import axios from 'axios';

export async function companyStatements() {
  const { data } = await axios.get('/company-statements');
  return data;
}

export async function companyStatementAnswer(id, payload) {
  const { data } = await axios.put(`/company-statements/${id}`, payload);
  return data;
}
