import axios from 'axios';
import { format } from 'date-fns';

export async function checkIsActiveVatPayerApiMethod(nip) {
  const instance = axios.create();
  delete instance.defaults.headers.common['Authorization'];

  const date = format(new Date, 'yyyy-MM-dd')
  const { data } = await instance.get(`https://wl-api.mf.gov.pl/api/search/nip/${nip}?date=${date}`);
  return data;
}
