import Vue from 'vue';
import axios from 'axios';

export default {
  init({ store, router }) {
    this.loading = false;
    this.store = store;
    this.router = router;
    this.initAxiosInterceptors({ store, router });
    this.initAuthGuard({ store, router });
    this.initRoleGuard({ store, router });
    Object.defineProperties(Vue.prototype, {
      $isGranted: {
        get() {
          return store.getters['auth/isGranted'];
        },
      },
      $isGrantedAdminOr: {
        get() {
          return store.getters['auth/isGrantedAdminOr'];
        },
      },
      $isGrantedByToken: {
        get() {
          return store.getters['auth/isGrantedByToken'];
        },
      },
    });
  },
  initAxiosInterceptors({ store }) {
    axios.interceptors.request.use(config => {
      const { token } = store.state.auth;
      const hasAuthHeader = !!config.headers.Authorization;
      if (token && !hasAuthHeader) {
        this.assignAuthorizationHeader(config);
      }
      return config;
    });
  },
  initAuthGuard({ router, store }) {
    const { auth } = store.state;
    const checkRouteAuth = (to, from, next) => {
      let authRequired;
      if (to.matched.length) {
        for (let i = 0; i < to.matched.length; i += 1) {
          const item = to.matched[i];
          if (
            typeof item.meta !== 'undefined' &&
            typeof item.meta.auth !== 'undefined'
          ) {
            authRequired = item.meta.auth;
          }
        }
      }
      const isAuthenticated = store.getters['auth/isAuthenticated'];

      if (authRequired === true && !isAuthenticated) {
        next({ name: 'login' });
      } else if (authRequired === false && isAuthenticated) {
        next({ name: 'dashboard' });
      } else {
        next();
      }
    };
    router.beforeEach(async (to, from, next) => {
      if (auth.ready === false && auth.token) {
        try {
          await store.dispatch('auth/init');
        } catch (ex) {
          store.commit('auth/CLEAR');
        }
      }
      checkRouteAuth(to, from, next);
    });
  },
  initRoleGuard({ router, store }) {
    router.beforeEach((to, from, next) => {
      if (!to.meta || !to.meta.role) {
        return next();
      }
      const isGranted = store.getters['auth/isGranted'];
      if (isGranted(to.meta.role)) {
        return next();
      }

      return next({ name: 'error-not-found' });
    });
  },
  assignAuthorizationHeader(requestConfig) {
    const switchUserHeader = localStorage.getItem('switch_user') ? {'x-switch-user': localStorage.getItem('switch_user')} : {};
    const { store } = this;
    Object.assign(requestConfig.headers, {...{
        Authorization: `Bearer ${store.state.auth.token}`,
      }, ...switchUserHeader});
  },
};
